
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/* You can add global styles to this file, and also import other style files */
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");


@font-face {
  font-family: ‘appfont’;
  font-style: normal;
  font-weight: normal;
  src: url("../src/assets/fonts/Open_Sans/OpenSans/OpenSans-Regular.ttf");
}

:root {
  --ion-font-family: ‘appFont’ !important;
  --ion-default-font: ‘appFont’ !important;
}

.bg-default{
  background-color: #21252910;
  color: #000 !important;
}


.d_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  .spinner-border {
    color: #666666 !important;
  }
}

.d_internal_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
  .spinner-border {
    color: #666666 !important;
  }
}

.div_permission{
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
  .ri-pencil-fill{
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 23px;
    cursor: pointer;
  }
  .p_permisson{
    margin-bottom: 3px;
    margin-top: 3px;
    position: relative;
    padding-left: 20px;
  }
  .icon_lock{
    font-size: 50px;
    color: #525252;
  }
  .ri-close-circle-fill{
    color: rgb(176, 14, 14);
    margin-right: 5px;
    position: absolute;
    left: 0;
  }
  .ri-checkbox-circle-fill{
    color: rgb(21, 129, 21);
    margin-right: 5px;
    position: absolute;
    left: 0;
  }
}

td {
  position: relative;
}

.card_event_table {
  padding-bottom: 80px;
}

.dp_ab {
  top: 5px;
  position: absolute !important;
  z-index: 99 !important;
}

.pagination_t {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bt_add_email {
  position: absolute;
  top: 5px;
  right: 20px;
}

.div_code {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_code {
  max-width: 150px;
  height: 50px;
  border-radius: 3px;
  border: 2px solid #ddd;
  text-align: center;
  letter-spacing: 5px;
}

.input_code[type="text"] {
  font-size: 24px;
}

.tf-Pagination {
  align-items: center;
}

.td_pagination {
  border: none !important;
}

.mini_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
  .spinner-border {
    color: #666666 !important;
  }
}
.btn_download {
  position: absolute;
  top: 6.3rem;
  left: 1rem;
  color: #192753;
  background-color: #eee;
  border-radius: 5px;
}

.btn_show_doc {
  position: absolute;
  top: 6.3rem;
  left: 3rem;
  border-radius: 5px;
}

.doc_modal {
  border-radius: 3px;
  position: relative;
  transition: all 0.2s;
  &.one {
    transform: rotate(90deg);
  }
  &.two {
    transform: rotate(180deg);
  }
  &.three {
    transform: rotate(270deg);
  }
}

.body_doc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame_file_modal {
  width: 100%;
  height: 70vh;
}

.icon_contact_copy {
  font-size: 15px;
  margin-top: -5px;
}

.icon_copy_doc {
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}

.profile_img {
  height: 150px !important;
  width: 150px !important;
  object-fit: scale-down !important;
}

.sp_error {
  color: red;
}

.div_empty_result {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deafaut_loading {
  --background: transparent;
  color: #fff;
  --spinner-color: #fff;
  --backdrop-opacity: 60%;
  box-shadow: none !important;
}

.user_info {
  margin-right: 8px;
}

.shadow {
  box-shadow: -2px 7px 7px -2px rgb(0 0 0 / 16%);
}

.sp_contact_vehicle {
  padding: 5px;
  padding-left: 20px;
  padding-right: 35px;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 3px;
  background-color: #eee;
  font-size: 11px;
  position: relative;
  i {
    position: absolute;
    left: 5px;
    top: 6px;
  }
  .div_copy {
    position: absolute;
    height: 100%;
    width: 30px;
    background-color: rgb(251, 251, 251);
    border: 2px #eee solid;
    right: 0;
    top: 0;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.span_info_vehicle {
  display: inline-block;
  padding: 10px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 3px;
  div {
    text-transform: uppercase;
    font-size: 11px;
    &.data_ {
      font-weight: bold;
    }
  }
}

.sort {
  cursor: pointer;
}

.img_credit_brand {
  height: 25px;
  margin-right: 5px;
  .sp_name_bd_cd {
    display: inline;
  }
}

.card_info {
  display: flex;
  align-items: center;
  padding-right: 40px;
}

.card_expire_message {
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
    margin-right: 5px;
  }
}

.card_name {
  display: flex;
  align-items: center;
  .sp_brand {
    margin-right: 15px;
    text-transform: capitalize;
  }
}

.my_content {
  overflow-y: scroll !important;
  overflow-x: hidden;
  height: calc(100vh - 170px);
}
.my_content::-webkit-scrollbar {
  width: 5px;
  margin: 0;
  padding: 0;
}

/* Track */
.my_content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.my_content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgb(220, 220, 220);
}

/* Handle on hover */
.my_content::-webkit-scrollbar-thumb:hover {
  background: rgb(206, 206, 206);
}

#drop_actions {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-bottom: 10px;
}

.profile_card {
  padding: 10px 10px 0 10px;
}

.phase_icon {
  padding: 3px;
  background-color: #dbe6fc;
  border-radius: 100%;
  margin-bottom: -5px !important;
  margin-left: 8px;
  color: #666666;
}

.profile_options {
  padding: 0px 10px 0 10px;
}

@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

table {
  width: 100%;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    td {
      height: 50px;
      vertical-align: middle;
      padding: 8px;
      span {
        display: block;
      }
      &.td-1 {
        width: 20px;
        span {
          width: 20px;
          height: 20px;
        }
      }
      &.td-2 {
        width: 50px;
        span {
          background-color: rgba(0, 0, 0, 0.15);
          width: 50px;
          height: 50px;
        }
      }
      &.td-3 {
        width: 400px;
        // padding-right: 100px;
        span {
          height: 12px;
          background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
          background-size: 500px 100px;
          animation-name: moving-gradient;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }
      &.td-4 {
      }
      &.td-5 {
        width: 100px;
        span {
          background-color: rgba(0, 0, 0, 0.15);
          width: 100%;
          height: 30px;
        }
      }
    }
  }
}

.link_bold {
  cursor: pointer;
}

.cspointer {
  cursor: pointer;
}

.work_day {
  display: inline-block;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin: 5px;
  &.active {
    background-color: rgb(9, 123, 9);
    color: #fff;
  }
}

.back_associate {
  display: flex;
  align-items: center;
  i {
    font-size: 22px;
    margin-right: 5px;
    cursor: pointer;
  }
}

@media (min-width: 991.98px) {
  .my_scroll {
    height: calc(100vh - 170px);
    overflow-y: scroll !important;
  }
  .my_scroll::-webkit-scrollbar {
    display: none;
  }
  .scroll_login {
    height: 100vh;
    overflow-y: scroll !important;
  }
  .scroll_login::-webkit-scrollbar {
    display: none;
  }
}

.toggler-wrapper {
	display: block;
	width: 45px;
	height: 25px;
	cursor: pointer;
	position: relative;
}

.toggler-wrapper input[type="checkbox"] {
	display: none;
}

.toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
	background-color: #44cc66;
}

.toggler-wrapper .toggler-slider {
	background-color: #ccc;
	position: absolute;
	border-radius: 100px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

/*Style 1*/
.toggler-wrapper .toggler-knob {
	position: absolute;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}


.toggler-wrapper.style-1 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
	left: calc(100% - 19px - 3px);
}

.toggler-wrapper.style-1 .toggler-knob {
	width: calc(25px - 6px);
	height: calc(25px - 6px);
	border-radius: 50%;
	left: 3px;
	top: 3px;
	background-color: #fff;
}

.card_left{
  align-items: center;
  justify-content: start;
  display: flex;
}

.back_icon{
  font-size: 23px;
  margin-right: 5px;
  cursor: pointer;
}

.header_information{
  font-weight: bold;
}


.body_information{
  font-weight: bold;
}

.body_title{
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.my_skeleton_loading {
  background: #eee;
  background: linear-gradient(110deg, #8d8d8d1f 8%, #68686838 18%, #8d8d8d1f 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.bastani_tabs button {
    margin-right: 0.1rem !important;
}
