/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

// Select 2 
@import "~@ng-select/ng-select/themes/default.theme.css";

// Arch Wizard 
@import "~angular-archwizard/styles/archwizard.css";


// Icons Sizes
.icon-xs {
    height: 16px !important;
    width: 16px !important;
  }
  
  .icon-sm {
    height: 18px !important;
    width: 18px !important;
  }
  
  .icon-md {
    height: 22px !important;
    width: 22px !important;
  }
  
  .icon-lg {
    height: 24px !important;
    width: 24px !important;
  }
  
  .icon-xl {
    height: 28px !important;
    width: 28px !important;
  }
  
  .icon-xxl {
    height: 32px !important;
    width: 32px !important;
  }
  
  .feather-icon-align{
    svg{
      vertical-align: text-top;
    }
  }
  
  .close{
    box-sizing: content-box;
    width: $btn-close-width;
    height: $btn-close-height;
    padding: $btn-close-padding-y $btn-close-padding-x;
    color: $btn-close-color;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
    border: 0; // for button elements
    @include border-radius();
    opacity: $btn-close-opacity;
    margin-right: $toast-padding-x * -.5;
    margin-left: $toast-padding-x !important;
    span{
      display: none;
    }
  }
  
  .list-group-flush .list-group-item{
    border-width: 0 0 1px
  }

  
  // topbar
  
  #page-topbar {
    .navbar-header {
      .header-item {
        .dropdown-toggle:after{
          display: none;
        }
      }
    }
  }
  
  // [data-layout="horizontal"] .main-content{
  //   min-height: 100vh;
  // }
  
  // @media (min-width: 1024.1px){
  //   [data-layout="horizontal"] .main-content{
  //       min-height: calc(100vh - 70px);
  //   }
  // }
  
  .checkout-accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
  
  .dropzone_sec {
    border: 2px dashed #e2e5e8 !important;
  }
  
  .dropzone .dz-message {
    font-size: 24px;
    min-height: 150px !important;
    border: none !important;
    background: #fff !important;
    color: #495057 !important;
  }
  
  // Calendar
  .flatpickr-calendar.open{
    z-index: 1061;
  }
  // scroll bar
  .simplebar-scrollbar:before{
    width: 100%;
    background: #a2adb7 !important;
  }
  
  @each $color, $value in $theme-colors {
    [data-simplebar-track="#{$color}"]{
      .simplebar-scrollbar:before{
        background: $value !important;
      }
    }
  }
  
  
  // ------------ Base Ui ---------//
  // Alert
  .custom-alert {
    .close {
      background: transparent;
      border: 0;
      font-size: 10px;
      padding: 1.35rem 1.25rem;
      background: transparent escape-svg($btn-close-bg) center / $btn-close-width
        auto no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.5;
      width: 1em;
      height: 1em;
      z-index: 2;
      color: $btn-close-color;
  
      span {
        display: none;
      }
    }
  }
  
  //accordion
  
  .accordion{
    .card{
      margin-bottom: 0;
      box-shadow: none;
      border-bottom: 1px solid $border-color;
      .card-header{
        padding: 0;
        border-bottom: 0;
        > .btn.btn-link{
          padding: 0;
          width: 100%;
          text-decoration: none;
        }
      }
    }
  }
  
  //Dropdown
  .dropdown-toggle {
    &.arrow-none {
      &::after {
        content: "";
      }
    }
  }
  
  
  
  
  // ----- Advance Ui --------//
  // Rating
  .stars {
    font-size: 30px;
    color: $warning;
  }
  .star {
    position: relative;
    display: inline-block;
    font-size: 30px;
    color: $warning;
  }
  .star_fill {
    color: $gray-500;
  }
  .full {
    color: #b0c4de;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: $warning;
  }
  .filled {
    color: #1e90ff;
  }
  .bad {
    color: #deb0b0;
  }
  .filled.bad {
    color: #ff1e1e;
  }
  
  //Swiper Slider
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-family: remixicon !important;
  }
  
  // Form wizard
  .custom-nav {
    aw-wizard-navigation-bar {
      &.horizontal {
        &.large-empty-symbols {
          ul {
            &.steps-indicator {
              padding: 48px 0 6px 0;
              li {
                .step-indicator {
                  width: 32px;
                  height: 32px;
                  line-height: 0px;
                  background-color: var(--#{$variable-prefix}light);
                  border-color: var(--#{$variable-prefix}light);
                  left: -14px;
                  top: -42px;
                  z-index: 1;
                  .nav-item {
                    .nav-link {
                      background-color: transparent;
                      width: 32px !important;
                      height: 32px !important;
                      padding: 0px;
                      margin-left: -2px;
                    }
                  }
                }
                &.current {
                  .step-indicator {
                    border-color: $primary;
                    background-color: $primary;
                    .nav-item {
                      .nav-link {
                        color: $white;
                      }
                    }
                  }
                }
                &.done {
                  .step-indicator {
                    border-color: $primary;
                    background-color: $primary;
                    .nav-item {
                      .nav-link {
                        color: $white;
                      }
                    }
                  }
                  &:not(:last-child) {
                    &::after {
                      background-color: $primary;
                    }
                  }
                }
                &:not(:last-child) {
                  &::after {
                    background-color: var(--#{$variable-prefix}light);
                    width: 100%;
                    left: calc(50% + 15px);
                    z-index: 0;
                  }
                }
                &.navigable {
                  a {
                    &:hover {
                      .step-indicator {
                        width: 32px;
                        height: 32px;
                        line-height: 0px;
                        border-color: $primary;
                      }
                    }
                  }
                  &.done {
                    a {
                      &:hover {
                        .step-indicator {
                          border-color: $primary;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  .custom-nav {
    &.nav-wizard {
      aw-wizard-navigation-bar {
        &.horizontal {
          &.large-empty-symbols {
            ul {
              &.steps-indicator {
                li {
                  text-align: left;
                  .step-indicator {
                    width: 250px;
                    border-radius: 0px;
                    left: 0px;
                    @media (max-width: 575.98px) {
                      width: 130px !important;
                    }
                    .nav-item {
                      .nav-link {
                        padding: $nav-link-padding-y $nav-link-padding-x;
                        color: $dark;
                        font-weight: $font-weight-medium;
                        width: 250px !important;
                        margin-left: 0px;
                        @media (max-width: 575.98px) {
                          width: 130px !important;
                        }
                      }
                    }
                  }
                  &.current {
                    .step-indicator {
                      border-color: transparent;
                      background-color: rgba($primary, 0.2);
                      .nav-item {
                        .nav-link {
                          color: $primary;
                        }
                      }
                    }
                  }
                  &.done {
                    .step-indicator {
                      border-color: transparent;
                      background-color: rgba($primary, 0.2);
                      .nav-item {
                        .nav-link {
                          color: $primary;
                        }
                      }
                    }
                    &:not(:last-child) {
                      &::after {
                        background-color: transparent;
                      }
                    }
                  }
                  &:not(:last-child) {
                    &::after {
                      background-color: transparent;
                    }
                  }
                  &.navigable {
                    a {
                      &:hover {
                        .step-indicator {
                          width: 250px;
                          border-radius: 0px;
                        }
                      }
                    }
                    &.done {
                      a {
                        &:hover {
                          .step-indicator {
                            border-color: transparent;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  
  //form
  
  //select
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-optgroup{
        font-weight: $font-weight-semibold;
        font-size: 12px;
        padding: 10px 16px;
        border-bottom: 1px solid $border-color;
        color: $text-muted;
      }
  
      .ng-option{
        &.ng-option-child{
          position: relative;
            font-size: $dropdown-font-size;
            padding: $dropdown-item-padding-y $dropdown-item-padding-x $dropdown-item-padding-y 16px;
        }
  
        &.ng-option-selected, &.ng-option-selected.ng-option-marked{
          background-color: $dropdown-link-hover-bg;
          color: $dropdown-color;
        }
  
        &.ng-option-marked{
          background-color: $dropdown-link-hover-bg;
          color: $dropdown-color;
        }
      }
    }
  }
  // multiple
  .ng-select.ng-select-multiple {
    .ng-select-container {
      .ng-value-container .ng-value{
        color: $white;
        background-color: $primary;
        border-radius: 5px;
        .ng-value-icon:hover{
          background-color: $primary;
        }
      }
    }
  }
  
  .ng-select.ng-select-disabled>.ng-select-container{
    background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
    .ng-value-container .ng-value.ng-value-disabled{
      background-color: $secondary;
    }
  }
  
  
  
  // grid js
  
  
  .table-gridjs {
    th,
    td {
      border: 1px solid $table-border-color;
      padding: $table-cell-padding-y $table-cell-padding-x;
    }
  
    th {
      color: $table-color;
      background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.75);
  
      &.gridjs-th-sort {
        &:focus,
        &:hover {
          background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.85);
        }
      }
    }
  }
  
  .gridjs-pagination {
    .pagination {
      margin: 0;
      .page-item {
        .page-link {
          margin-left: 0.3rem;
          border-radius: $border-radius !important;
          border: 1px solid $pagination-border-color;
        }
      }
    }
  }
  
  .gridjs-checkbox{
    width: $form-check-input-width;
    height: $form-check-input-width;
    vertical-align: top;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    appearance: none;
    color-adjust: exact; // Keep themed appearance for print
    @include transition($form-check-transition);
    &[type="checkbox"] {
      @include border-radius($form-check-input-border-radius);
    }
  }
  
  .gridjs-checkbox[type=checkbox]:checked{
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
    @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
  }
  
  // list js
  
  .listjs-pagination{
    .pagination{
      margin-bottom: 0;
      justify-content: flex-end;
      gap: 8px;
    }
  }
  
  // profile timeline
  
  .profile-timeline{
    .card{
      position: relative;
      border: 0;
  
      .accordion-button {
          background-color: transparent;
  
          &::after {
              background: none;
          }
      }
  
      &::before {
          content: "";
          border-left: 2px dashed $border-color;
          position: absolute;
          height: 100%;
          left: 23px;
      }
  
      &:first-child {
          &::before {
              top: 8px;
          }
      }
  
      &:last-child {
          &::before {
              height: 20px;
              top: 3px;
          }
      }
  }
  }
  
  // task
  // kanban board
  .tasks{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .kanban-accordion{
    .card{
      box-shadow: $box-shadow;
    }
    > .card{
      background-color: transparent;
      box-shadow: none;
      border: none;
      > .collapse{
        > .card-body{
          padding: 0;
  
          .card{
            margin-bottom: 0.25rem;
          }
        }
  
        .card-header {
          > .btn.btn-link{
            padding: 16px;
          }
        }
      }
      .card-header {
        background-color: transparent;
      }
    }
  }
  
  // dashboard
  
  .upcoming-scheduled{
    input[readonly]{
      display: none;
    }
  }
  
  .products-filter{
    .card {
      border-bottom: none;
    }
  }
  
  .light-dark-mode{
    .bx-moon {
      display: block;
    }
    .bx-sun {
      display:none;
    }
  }
  
  // dropzone
  
  dropzone>.dropzone.dz-wrapper .dz-message .dz-text{
    font-size:16px;
  }
  
  // Swiper
  
  .swiper-pagination-bullet{
    width: 22px !important;
    height: 5px !important;
    background-color: $white !important;
    border-radius: 50px !important;
    box-shadow: $element-shadow !important;
    border: none !important;
    .swiper-pagination-bullet-active{
        opacity: 1 !important;
    }
  }
  
  swiper>.swiper.s-wrapper .swiper-pagination .swiper-pagination-handle{
    height: 10px !important;
  }
  
  .dynamic-pagination{
    .swiper-pagination-bullet {
        width: 8px !important;
        height: 8px !important;
        background-color: $white !important;
        opacity: 0.5 !important;
        transition: all 0.5s ease;
        &.swiper-pagination-bullet-active{
            opacity: 1 !important;
            width: 20px !important;
        }
    }
  }
  
  [data-layout-mode="dark"] {
    .light-dark-mode{
      .bx-moon {
        display: none;
      }
      .bx-sun {
        display:block;
      }
    }
  }
   
  
  @media (max-width: 600px)
  {
    .navbar-header .dropdown .dropdown-menu {
      left: 10px !important;
      right: 10px !important;
      transform: translate(0, 56px) !important;
      width: auto !important;
    }
  }
  
  .dropdown-menu-end {
    left: auto !important;
    right: 0 !important;
  }
  
  // Pages Team
  .team-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(33, 37, 41, 0.35);
    z-index: 1003;
    display: none;
  }
  .team-overlay.show{
    display: block;
  }
  
  // twostep input
  
  .twostep-input{
    .wrapper{
      display: flex;
      justify-content: center;
      input{
        background-color: $light;
        border-color: $light;
        &:focus-visible{
          outline: none;
        }
      }
    }
  }
  
  // team list
  .team-list.grid-view-filter .team-box .team-settings .dropdown{
    .dropdown-menu{
      transform: translate(0, 26px) !important;
    }
  }
  
  //autocomplete
  .autocomplete-container{
    box-shadow: none !important;
  }
  .autocomplete-container .input-container input{
    background-color: $input-bg !important;
    border: 1px solid $input-border-color !important;
    height: 37.5px !important;
    @include border-radius($input-border-radius, 0);
    
  }
  
  .autocomplete-container .input-container .x {
    right: 6px;
    i{
      font-size: 12px !important;
    }
  }

  // landing
  .testimonial-slider{
    .swiper-button-next, .swiper-button-prev{
      height: 32px !important;
      width: 32px !important;
      background-color: $white;
      border-radius: 50%;
      &:after{
        font-size: 28px !important;
      }
    }
    .swiper-pagination{
      position: relative;
    }
  }

.marketplace-swiper {
  .swiper-wrapper {
      padding-top: 75px;
  }
}

.marketplace-swiper {
    .swiper-button-next,
    .swiper-button-prev {
        top: 52px;
        &::after {
            font-size: 28px;
        }
    }
}


// Data Table Css
.custom-datatable {
  &.table {
      &.dataTable {
          width: 100%;
          margin: 0 auto;
          clear: both;
          border-collapse: collapse;
          border-spacing: 0;
      }

      &.no-footer {
          border-bottom: $border-width solid $table-border-color;
      }

      thead th,
      thead td {
          border-bottom: 1px solid $table-border-color;
      }
  }
}


.dataTables_wrapper {
  .dataTables_paginate {
      &.paging_simple_numbers {
          padding-top: 1.25em;

          .paginate_button {
              padding: $pagination-padding-y $pagination-padding-x;
              border-radius: $pagination-border-radius-sm;
              border: $pagination-border-width solid $pagination-border-color;

              &.disabled,
              &.disabled:hover,
              &.disabled:active {
                  cursor: default;
                  color: #666;
                  border: $pagination-border-width solid $pagination-border-color;
                  background: transparent;
                  box-shadow: none;
              }

              &.current {
                  background-color: $pagination-active-bg;
                  color: $pagination-active-color  !important;
                  border-color: $pagination-active-border-color;

                  &:hover {
                      background-color: $pagination-active-bg;
                      color: $pagination-active-color  !important;
                      border-color: $pagination-active-border-color;
                  }
              }

              &:hover {
                  background: $pagination-hover-bg;
                  color: $pagination-hover-color  !important;
                  border-color: $pagination-hover-border-color;
              }
          }
      }
  }

  .dataTables_info {
      padding-top: 1.75em !important;
  }
}