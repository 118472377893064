/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Version: 1.6.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Icons Css File
*/

// Icons Scss
@import "plugins/icons/materialdesignicons";
@import "plugins/icons/boxicons";
@import "plugins/icons/line-awesome";
@import "plugins/icons/remixicon";